<template>
	<div class="header">
		<div class="logo">{{shop_name}}</div>
		<div class="header-right">
			<div class="header-user-con">
				<!-- 消息中心 -->
				<el-dropdown class="user-avator" @command="handleCommand">
					<span class="el-dropdown-link">客服消息</span>
					<el-dropdown-menu slot="dropdown"  >
						<el-dropdown-item divided class="user-avator-content">
							<div class="topInfo">
								<div class="code">
									<img src="@/assets/img/qrecode.png" />
								</div>
								<div class="userinfo">
									<p style="margin-top: 10px;line-height: 24px;">客服</p>
									<!-- <p style="font-size: 12px; color: #999;line-height: 24px;">众康联</p> -->
								</div>
							</div>
							<div class="downInfo">
								<p style="font-size: 14px; color: #333;line-height: 24px;text-align: left;">工作时间：</p>
								<p style="line-height: 24px;text-align: left;">周一至周五 9:00-12:00 13:30-18:00</p>
								<p style="font-size: 14px; color: #333;text-align: left;">工作内容：</p>
								<p style="line-height: 24px;text-align: left;">提供运营管理、销售拓展和政策交流等方面的解答</p>
							</div>
						</el-dropdown-item>
					</el-dropdown-menu>
				</el-dropdown>
				<!-- 用户名下拉菜单 -->
				<el-dropdown class="user-name" @command="handleCommand">
					<span class="el-dropdown-link">
						<img src="../../assets/img/img.png" style="width: 38px; height: 40px;margin-top:35px" />
						<!--  {{username}}
		                <i class="el-icon-caret-bottom"></i> -->
					</span>
					<el-dropdown-menu slot="dropdown">
						<el-dropdown-item divided command="accountInfo"><img src="../../assets/img/accoutnBtn.png" style="width: 18px;vertical-align: middle; height: 18px; margin-right: 5px;"/>账号信息</el-dropdown-item>
						<el-dropdown-item divided command="loginout"><img src="../../assets/img/quitBtn.png" style="width: 18px; height: 18px;vertical-align: middle; margin-right: 5px;"/>退出登录</el-dropdown-item>
					</el-dropdown-menu>
				</el-dropdown>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		quitLoginPlat
	} from "@/api/plat/platlogin";
	import {
		quitLoginShop
	} from "@/api/merchant/login";
	import {
		quitLoginChannel
	} from "@/api/channel/login";
	import {
		mapState
	} from 'vuex'
	export default {
		data() {
			return {
				message: 2,
				name: '',
				messageShow: false,
				shop_name:localStorage.getItem('shop_name')
			};
		},
		computed: {
			username() {
				let username = localStorage.getItem('nickname');
				return username ? username : this.name;
			}
		},
		methods: {
			// 用户名下拉菜单选择事件
			handleCommand(command) {
				if (command == 'accountInfo') {
					if(localStorage.getItem('terminal') == 'platform'){
						this.$router.push('/accountPlatInfo');
					}else if(localStorage.getItem("terminal") == 'shop'){
						this.$router.push('/accountMerchantInfo');
					}else if(localStorage.getItem("terminal") == 'channel'){
						this.$router.push('/accountChannelInfo');
					}
				}
				if (command == 'loginout') {
					quitLoginShop({}).then(res=>{
						this.$store.commit('LOGIN_OUT');
						this.$router.push('/shoplogin');
						localStorage.setItem("activeName",'first')
						localStorage.removeItem('dataset');
						localStorage.removeItem('terminal');
						localStorage.removeItem('refreshToken');
						localStorage.removeItem('head_portrait');
						localStorage.removeItem('shop_name');
						/* 防止切换角色时addRoutes重复添加路由导致出现警告 */
						window.location.reload()
					})
				}
			},
			//移入
			enter() {
				this.messageShow = true
			},
			//移出
			leave() {
				this.messageShow = false
			}
		}

	};
</script>

<style scoped="scoped" lang="scss">
	.header {
		position: relative;
		box-sizing: border-box;
		width: 100%;
		height: 59px;
		background: #FFFFFF;
		
	}

	.collapse-btn {
		float: left;
		padding: 0 21px;
		cursor: pointer;
		line-height: 59px;
	}

	.header .logo {
		padding-left: 15px;
		color: #333;
		font-size: 14px;
		float: left;
		width: 250px;
		line-height: 59px;
	}

	.header-right {
		float: right;
		padding-right: 50px;
	}

	.header-user-con {
		display: flex;
		height:59px;
		align-items: center;
	}

	.btn-fullscreen {
		transform: rotate(45deg);
		margin-right: 5px;
		font-size: 24px;
	}

	.btn-bell,
	.btn-fullscreen {
		position: relative;
		width: 30px;
		height: 30px;
		text-align: center;
		border-radius: 15px;
		cursor: pointer;
	}

	.btn-bell-badge {
		position: absolute;
		right: 0;
		top: -2px;
		width: 8px;
		height: 8px;
		border-radius: 4px;
		background: #f56c6c;
		color: #fff;
	}

	.btn-bell .el-icon-bell {
		color: #333;
	}

	.user-name {
		margin-left: 10px;
	}

	.user-avator {
		margin-left: 20px;
		margin-right: 20px;
		color: #333;
		font-size: 14px;
	}

	.user-avator:hover {
		color: #2589FF;
		cursor: pointer;
	}

	.user-avator-content {
		padding: 0;
		.topInfo {
			height: 80px;
			background: #EDF5FF;
			border-radius: 4px 4px 0px 0px;
			display: flex;
			.code {
				width: 78px;
				height: 78px;
				border-radius: 0;
				img {
					width: 100%;
					height: 100%;
				}
			}
			.userinfo {
				margin-left: 6px;
			}
		}

		.downInfo {
			padding: 10px 10px;
			font-size: 12px;
			color: #666;

		}

	}

	// .user-avator img {
	//     display: block;
	//     width: 40px;
	//     height: 40px;
	//     border-radius: 50%;
	// }
	.el-dropdown-link {
		color: #333;
		cursor: pointer;
	}

	.el-dropdown-menu__item {
		text-align: center;
	}

	::v-deep.el-dropdown-menu__item--divided {
		border-top: none;
		margin-top: 0;
	}

	.el-icon-caret-bottom {
		color: #333;
	}
</style>
