

//const baseUrl = 'http://192.168.2.189:8000/'     //测试服务器 https://hzym.api.aissyun.com    
//const baseUrl = 'https://dev.cattleapi.gcaimall.com'
// const baseUrl = 'https://ydl.api.aissyun.com'
// const baseUrl = 'https://hzym.api.aissyun.com' //正式
// const baseUrl = 'https://hzymtest.api.aissyun.com' //测试
const baseUrl = 'https://ssjf.api.aissyun.com' //正式
// const baseUrl = 'https://ssjftest.api.aissyun.com' //测试
 //const baseUrl = 'http://192.168.0.19:81'

export default baseUrl
